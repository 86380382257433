/* globals window, document, GOOGLE_MAPS_API_KEY */

/**
 * Webflow: Maps widget
 */

var Webflow = require('../BaseSiteModules/webflow-lib');

Webflow.define(
  'maps',
  (module.exports = function ($, _) {
    var api = {};
    var $doc = $(document);
    var google = null;
    var $maps;
    var namespace = '.w-widget-map';
    // The API key is injected here from the Webflow Integrations tab on the site's dashboard during site publish
    // ... but while designing it needs to be set to avoid a console error about it being undefined
    var googleMapsApiKey =
      typeof GOOGLE_MAPS_API_KEY !== 'undefined' ? GOOGLE_MAPS_API_KEY : '';

    // -----------------------------------
    // Module methods

    api.ready = function () {
      // Init Maps on the front-end
      if (!Webflow.env()) {
        initMaps();
      }
    };

    api.destroy = removeListeners;

    // -----------------------------------
    // Private methods

    function initMaps() {
      $maps = $doc.find(namespace);
      if (!$maps.length) {
        return;
      }

      if (google === null) {
        $.getScript(
          'https://maps.googleapis.com/maps/api/js?v=3.31&sensor=false&callback=_wf_maps_loaded&key=' +
            googleMapsApiKey
        );
        window._wf_maps_loaded = mapsLoaded;
      } else {
        mapsLoaded();
      }

      function mapsLoaded() {
        window._wf_maps_loaded = function () {};
        google = window.google;
        $maps.each(renderMap);
        removeListeners();
        addListeners();
      }
    }

    function removeListeners() {
      Webflow.resize.off(resizeMaps);
      Webflow.redraw.off(resizeMaps);
    }

    function addListeners() {
      Webflow.resize.on(resizeMaps);
      Webflow.redraw.on(resizeMaps);
    }

    // Render map onto each element
    function renderMap(i, el) {
      var data = $(el).data();
      getState(el, data);
    }

    function resizeMaps() {
      $maps.each(resizeMap);
    }

    // Resize map when window changes
    function resizeMap(i, el) {
      var state = getState(el);
      google.maps.event.trigger(state.map, 'resize');
      state.setMapPosition();
    }

    // Store state on element data
    var store = 'w-widget-map';
    function getState(el, data) {
      var state = $.data(el, store);
      if (state) {
        return state;
      }

      var hasTooltip =
        typeof data.widgetTooltip === 'string' && data.widgetTooltip !== '';

      var $el = $(el);
      var title = $el.attr('title');

      var markerTitle = 'Map pin';
      if (title && data.widgetTooltip) {
        markerTitle = `Map pin on ${title} showing location of ${data.widgetTooltip}`;
      } else if (title && !data.widgetTooltip) {
        markerTitle = `Map pin on ${title}`;
      } else if (!title && data.widgetTooltip) {
        markerTitle = `Map pin showing location of ${data.widgetTooltip}`;
      }

      state = $.data(el, store, {
        // Default options
        latLng: '51.511214,-0.119824',
        tooltip: '',
        style: 'roadmap',
        zoom: 12,

        // Marker
        marker: new google.maps.Marker({
          draggable: false,
          title: markerTitle,
        }),

        // Tooltip infowindow
        infowindow: new google.maps.InfoWindow({
          disableAutoPan: true,
        }),
      });
      // LatLng center point
      if (
        typeof data.widgetLatlng === 'string' &&
        data.widgetLatlng.length !== ''
      ) {
        state.latLng = data.widgetLatlng;
      }
      var coords = state.latLng.split(',');
      var latLngObj = new google.maps.LatLng(coords[0], coords[1]);
      state.latLngObj = latLngObj;

      // Disable touch events
      var mapDraggable = !(Webflow.env.touch && !data.enableTouch);

      // Map instance
      state.map = new google.maps.Map(el, {
        center: state.latLngObj,
        zoom: state.zoom,
        maxZoom: 20,
        mapTypeControl: false,
        panControl: false,
        streetViewControl: false,
        scrollwheel: data.enableScroll,
        draggable: mapDraggable,
        zoomControl: true,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL,
        },
        mapTypeId: state.style,
      });
      state.marker.setMap(state.map);

      // Set map position and offset
      state.setMapPosition = function () {
        state.map.setCenter(state.latLngObj);
        var offsetX = 0;
        var offsetY = 0;
        var padding = $el.css([
          'paddingTop',
          'paddingRight',
          'paddingBottom',
          'paddingLeft',
        ]);
        offsetX -= parseInt(padding.paddingLeft, 10);
        offsetX += parseInt(padding.paddingRight, 10);
        offsetY -= parseInt(padding.paddingTop, 10);
        offsetY += parseInt(padding.paddingBottom, 10);
        if (offsetX || offsetY) {
          state.map.panBy(offsetX, offsetY);
        }
        $el.css('position', ''); // Remove injected position
      };

      // Fix position after first tiles have loaded
      google.maps.event.addListener(state.map, 'tilesloaded', function () {
        google.maps.event.clearListeners(state.map, 'tilesloaded');
        state.setMapPosition();
      });

      // Set initial position
      state.setMapPosition();
      state.marker.setPosition(state.latLngObj);
      state.infowindow.setPosition(state.latLngObj);

      // Draw tooltip
      if (hasTooltip) {
        var tooltip = data.widgetTooltip;
        state.tooltip = tooltip;
        state.infowindow.setContent(tooltip);
        if (!state.infowindowOpen) {
          state.infowindow.open(state.map, state.marker);
          state.infowindowOpen = true;
        }
      }

      // Map style - options.style
      var style = data.widgetStyle;
      if (style) {
        state.map.setMapTypeId(style);
      }

      // Zoom - options.zoom
      var zoom = data.widgetZoom;
      if (zoom != null) {
        state.zoom = zoom;
        state.map.setZoom(Number(zoom));
      }

      // Click marker to open in google maps
      google.maps.event.addListener(state.marker, 'click', function () {
        window.open(
          'https://maps.google.com/?z=' + state.zoom + '&daddr=' + state.latLng
        );
      });

      return state;
    }

    // Export module
    return api;
  })
);
